import { ResourceTypes } from '../resources.d';
import Resources from '../utils/resources';

import { SlotId } from './config';

export const MAPPED_SYMBOLS: Record<SlotId, { default: ResourceTypes; freespin?: ResourceTypes }> = {
  [SlotId.A]: { default: ResourceTypes.a },
  [SlotId.B]: { default: ResourceTypes.b },
  [SlotId.C]: { default: ResourceTypes.c },
  [SlotId.D]: { default: ResourceTypes.d },
  [SlotId.E]: { default: ResourceTypes.e },
  [SlotId.F]: { default: ResourceTypes.f },
  [SlotId.G]: { default: ResourceTypes.g },
  [SlotId.H]: { default: ResourceTypes.h },
  [SlotId.I]: { default: ResourceTypes.i },
  [SlotId.J]: { default: ResourceTypes.j },
  [SlotId.WL]: { default: ResourceTypes.wL },
};

export enum SymbolAnimationType {
  DEFAULT,
  SPRITE,
  SPINE,
}

export const MAPPED_SYMBOLS_WIN_ANIMATIONS: {
  [key in SlotId]: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.A]: {
    type: SymbolAnimationType.SPINE,
    src: 'a_symbol',
    animation: 'win',
  },
  [SlotId.B]: {
    type: SymbolAnimationType.SPINE,
    src: 'b_symbol',
    animation: 'win',
  },
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'c_symbol',
    animation: 'win',
  },
  [SlotId.D]: {
    type: SymbolAnimationType.SPINE,
    src: 'd_symbol',
    animation: 'win',
  },
  [SlotId.E]: {
    type: SymbolAnimationType.SPINE,
    src: 'e_symbol',
    animation: 'win',
  },
  [SlotId.F]: {
    type: SymbolAnimationType.SPINE,
    src: 'f_symbol',
    animation: 'win',
  },
  [SlotId.G]: {
    type: SymbolAnimationType.SPINE,
    src: 'g_symbol',
    animation: 'win',
  },
  [SlotId.H]: {
    type: SymbolAnimationType.SPINE,
    src: 'h_symbol',
    animation: 'win',
  },
  [SlotId.I]: {
    type: SymbolAnimationType.SPINE,
    src: 'i_symbol',
    animation: 'win',
  },
  [SlotId.J]: {
    type: SymbolAnimationType.SPINE,
    src: 'j_symbol',
    animation: 'win',
  },
  [SlotId.WL]: {
    type: SymbolAnimationType.SPINE,
    src: 'wild',
    animation: 'win',
  },
};

export const MAPPED_SYMBOLS_LAND_ANIMATIONS: {
  [key in SlotId]: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.A]: {
    type: SymbolAnimationType.SPINE,
    src: 'a_symbol',
    animation: 'land',
  },
  [SlotId.B]: {
    type: SymbolAnimationType.SPINE,
    src: 'b_symbol',
    animation: 'land',
  },
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'c_symbol',
    animation: 'land',
  },
  [SlotId.D]: {
    type: SymbolAnimationType.SPINE,
    src: 'd_symbol',
    animation: 'land',
  },
  [SlotId.E]: {
    type: SymbolAnimationType.SPINE,
    src: 'e_symbol',
    animation: 'land',
  },
  [SlotId.F]: {
    type: SymbolAnimationType.SPINE,
    src: 'f_symbol',
    animation: 'land',
  },
  [SlotId.G]: {
    type: SymbolAnimationType.SPINE,
    src: 'g_symbol',
    animation: 'land',
  },
  [SlotId.H]: {
    type: SymbolAnimationType.SPINE,
    src: 'h_symbol',
    animation: 'land',
  },
  [SlotId.I]: {
    type: SymbolAnimationType.SPINE,
    src: 'i_symbol',
    animation: 'land',
  },
  [SlotId.J]: {
    type: SymbolAnimationType.SPINE,
    src: 'j_symbol',
    animation: 'land',
  },
  [SlotId.WL]: {
    type: SymbolAnimationType.SPINE,
    src: 'wild',
    animation: 'land',
  },
};
export const LOADER_MAPPED_SYMBOLS = [
  { name: ResourceTypes.a, src: Resources.getSource(ResourceTypes.a) },
  { name: ResourceTypes.b, src: Resources.getSource(ResourceTypes.b) },
  {
    name: ResourceTypes.c,
    src: Resources.getSource(ResourceTypes.c),
  },
  {
    name: ResourceTypes.d,
    src: Resources.getSource(ResourceTypes.d),
  },
  { name: ResourceTypes.e, src: Resources.getSource(ResourceTypes.e) },
  {
    name: ResourceTypes.f,
    src: Resources.getSource(ResourceTypes.f),
  },
  {
    name: ResourceTypes.g,
    src: Resources.getSource(ResourceTypes.g),
  },
  {
    name: ResourceTypes.h,
    src: Resources.getSource(ResourceTypes.h),
  },
  {
    name: ResourceTypes.i,
    src: Resources.getSource(ResourceTypes.i),
  },
  {
    name: ResourceTypes.j,
    src: Resources.getSource(ResourceTypes.j),
  },
  {
    name: ResourceTypes.wL,
    src: Resources.getSource(ResourceTypes.wL),
  },
];

export const LOADER_TEXTURES = [
  { name: ResourceTypes.frame, src: Resources.getSource(ResourceTypes.frame) },
  {
    name: ResourceTypes.reelBackground,
    src: Resources.getSource(ResourceTypes.reelBackground),
  },
  {
    name: ResourceTypes.reelBackgroundRageMode,
    src: Resources.getSource(ResourceTypes.reelBackgroundRageMode),
  },
  {
    name: ResourceTypes.reelBackgroundFreeSpins,
    src: Resources.getSource(ResourceTypes.reelBackgroundFreeSpins),
  },
  {
    name: ResourceTypes.frameRageMode,
    src: Resources.getSource(ResourceTypes.frameRageMode),
  },
  {
    name: ResourceTypes.frameFreeSpins,
    src: Resources.getSource(ResourceTypes.frameFreeSpins),
  },
  {
    name: ResourceTypes.freeSpinsDefault,
    src: Resources.getSource(ResourceTypes.freeSpinsDefault),
  },
  {
    name: ResourceTypes.freeSpinsPressed,
    src: Resources.getSource(ResourceTypes.freeSpinsPressed),
  },
  {
    name: ResourceTypes.freeSpinsHover,
    src: Resources.getSource(ResourceTypes.freeSpinsHover),
  },
  {
    name: ResourceTypes.freeSpinsDisabled,
    src: Resources.getSource(ResourceTypes.freeSpinsDisabled),
  },
  {
    name: ResourceTypes.messageBanner,
    src: Resources.getSource(ResourceTypes.messageBanner),
  },
  {
    name: 'coinsAnimation',
    src: '/animations/coins/coins.json',
  },
  {
    name: 'a_symbol',
    src: '/animations/symbol_win/hp_1.json',
  },
  {
    name: 'b_symbol',
    src: '/animations/symbol_win/hp_2.json',
  },
  {
    name: 'c_symbol',
    src: '/animations/symbol_win/hp_3.json',
  },
  {
    name: 'd_symbol',
    src: '/animations/symbol_win/hp_4.json',
  },
  {
    name: 'e_symbol',
    src: '/animations/symbol_win/hp_5.json',
  },
  {
    name: 'f_symbol',
    src: '/animations/symbol_win/lp_1.json',
  },
  {
    name: 'g_symbol',
    src: '/animations/symbol_win/lp_2.json',
  },
  {
    name: 'h_symbol',
    src: '/animations/symbol_win/lp_3.json',
  },
  {
    name: 'i_symbol',
    src: '/animations/symbol_win/lp_4.json',
  },
  {
    name: 'j_symbol',
    src: '/animations/symbol_win/lp_5.json',
  },
  {
    name: 'wild',
    src: '/animations/symbol_win/wild.json',
  },
  {
    name: 'explosion',
    src: '/animations/symbol_win/symbol_explosion.json',
  },
  {
    name: 'winFrame',
    src: '/animations/symbol_win/win_frame.json',
  },
  {
    name: 'generalRandomWilds',
    src: '/animations/randomWilds/features_full_screen.json',
  },
  {
    name: 'meteorWild',
    src: '/animations/randomWilds/wild_features.json',
  },
  {
    name: 'reelAmbient',
    src: '/animations/reelAmbient/reel.json',
  },
  {
    name: 'bigWinMessages',
    src: '/animations/winMessages/special_messages.json',
  },
  {
    name: 'progress_bar_win',
    src: '/animations/progressBar/progress_bar_win.json',
  },
  {
    name: 'progress_bar',
    src: '/animations/progressBar/progress_bar.json',
  },
  {
    name: 'popups',
    src: '/animations/popups/pop_ups.json',
  },
  {
    name: 'multipliers',
    src: '/animations/multipliers/multiplayers.json',
  },
  {
    name: 'logo',
    src: '/animations/logo/logo.json',
  },
  {
    name: 'background',
    src: '/animations/background/background.json',
  },
  {
    name: 'buttonsSprite',
    src: '/images/buttons/buttons.json',
  },
  {
    name: 'infoBtnSprite',
    src: '/images/infoBtn/infomation.json',
  },
  {
    name: 'loaderSprite',
    src: '/images/loader/loader.json',
  },
  {
    name: ResourceTypes.progressBarBase,
    src: Resources.getSource(ResourceTypes.progressBarBase),
  },
  {
    name: ResourceTypes.progressBarFreeSpins,
    src: Resources.getSource(ResourceTypes.progressBarFreeSpins),
  },
  {
    name: ResourceTypes.labelRageMode,
    src: Resources.getSource(ResourceTypes.labelRageMode),
  },
  {
    name: ResourceTypes.slotBg,
    src: Resources.getSource(ResourceTypes.slotBg),
  },
  {
    name: ResourceTypes.slotBgFreeSpins,
    src: Resources.getSource(ResourceTypes.slotBgFreeSpins),
  },
  {
    name: ResourceTypes.slotBgRageMode,
    src: Resources.getSource(ResourceTypes.slotBgRageMode),
  },
  {
    name: ResourceTypes.introBg,
    src: Resources.getSource(ResourceTypes.introBg),
  },
  {
    name: ResourceTypes.introBgMobile,
    src: Resources.getSource(ResourceTypes.introBgMobile),
  },
  {
    name: ResourceTypes.introReel1,
    src: Resources.getSource(ResourceTypes.introReel1),
  },
  {
    name: ResourceTypes.introReel2,
    src: Resources.getSource(ResourceTypes.introReel2),
  },
  {
    name: ResourceTypes.introReel3,
    src: Resources.getSource(ResourceTypes.introReel3),
  },
  {
    name: ResourceTypes.buttonOk,
    src: Resources.getSource(ResourceTypes.buttonOk),
  },
  {
    name: ResourceTypes.buyFeatureRageBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureRageBtn),
  },
  {
    name: ResourceTypes.buyFeatureRageBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeatureRageBtnDisable),
  },
  {
    name: ResourceTypes.buyFeatureRageBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureRageBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureRageBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureRageBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtn),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnDisable),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtn,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtn),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnHover),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnPressed),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnDisable),
  },
  {
    name: ResourceTypes.buyFeatureOkBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtn),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnDisable),
  },
  {
    name: ResourceTypes.buyButtonDefault,
    src: Resources.getSource(ResourceTypes.buyButtonDefault),
  },
  {
    name: ResourceTypes.buyButtonHover,
    src: Resources.getSource(ResourceTypes.buyButtonHover),
  },
  {
    name: ResourceTypes.buyButtonDisabled,
    src: Resources.getSource(ResourceTypes.buyButtonDisabled),
  },
  {
    name: ResourceTypes.buyButtonPressed,
    src: Resources.getSource(ResourceTypes.buyButtonPressed),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtn),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtnDisable),
  },
  {
    name: ResourceTypes.buyFeatureXBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureXBtn),
  },
  {
    name: ResourceTypes.buyFeatureXBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureXBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureXBtnDisabled,
    src: Resources.getSource(ResourceTypes.buyFeatureXBtnDisabled),
  },
  {
    name: ResourceTypes.buyFeatureXBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureXBtnHover),
  },
  {
    name: ResourceTypes.buyFeaturePopup,
    src: Resources.getSource(ResourceTypes.buyFeaturePopup),
  },
  {
    name: ResourceTypes.backdrop,
    src: Resources.getSource(ResourceTypes.backdrop),
  },
  {
    name: ResourceTypes.buyFeaturePopupConfirm,
    src: Resources.getSource(ResourceTypes.buyFeaturePopupConfirm),
  },
  {
    name: ResourceTypes.buyFeaturePopupBackplate,
    src: Resources.getSource(ResourceTypes.buyFeaturePopupBackplate),
  },
  {
    name: ResourceTypes.freeSpinsCounter,
    src: Resources.getSource(ResourceTypes.freeSpinsCounter),
  },
];

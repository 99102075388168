import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { EnterProps, EventTypes, GameMode, ISettledBet } from '../../global.d';
import {
  setBetAmount,
  setBetResult,
  setBrokenGame,
  setCurrentBonus,
  setFreeSpinsTotalWin,
  setGameHistory,
  setIsBuyFeaturePurchased,
  setIsFreeSpinsWin,
  setIsProcessToGame,
  setIsRevokeThrowingError,
  setIsSlotBusy,
  setIsSpinInProgress,
  setIsTimeoutErrorMessage,
  setLastRegularWinAmount,
  setSkipIntroScreen,
  setSlotConfig,
  setUserBalance,
  setWinAmount,
} from '../../gql/cache';
import client from '../../gql/client';
import { isStoppedGql } from '../../gql/query';
import SlotMachine from '../../slotMachine';
import { WinStages, eventManager } from '../../slotMachine/config';
import IntroScreen from '../../slotMachine/introScreen/introScreen';
import Reel from '../../slotMachine/reels/reel';
import {
  getBetResult,
  getSpinResult,
  getWinStage,
  normalizeCoins,
  saveReelPosition,
  updateCoinValueAfterBonuses,
} from '../../utils';
import { States } from '../config';
import { Logic } from '../index';

import { Controller } from './Controller';

export class BaseController extends Controller {
  public gameMode: GameMode = GameMode.BASE_GAME;

  public static the = new BaseController();

  private slotIdleTimeout: ReturnType<typeof setTimeout> | undefined;

  protected constructor() {
    super();
  }

  public enterInitState(prevState: States): void {
    if (!setSkipIntroScreen()) {
      Logic.the.changeState(States.INTRO);
      return;
    }
    if (setBrokenGame()) {
      Logic.the.changeState(States.BROKEN_GAME);
      return;
    }
    Logic.the.changeState(States.IDLE);
  }

  public exitInitState(nextState: States): void {
    if (nextState === States.INTRO) return;

    SlotMachine.initSlotMachine(setSlotConfig());
    // overriding it here coz slotmachine index.ts is a critical file, and cannot be modified.
    SlotMachine.the().throwTimeoutError = () => {
      if (!setIsRevokeThrowingError()) {
        setIsTimeoutErrorMessage(true);
      }
      eventManager.emit(EventTypes.THROW_ERROR);
    };

    eventManager.emit(EventTypes.FORCE_RESIZE);
    if (nextState === States.IDLE) {
      setIsProcessToGame(true);
    }
  }

  public enterIntroState(prevState: States): void {
    IntroScreen.initIntroScreen();
    eventManager.emit(EventTypes.FORCE_RESIZE);
    eventManager.once(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
      if (setBrokenGame()) {
        Logic.the.changeState(States.BROKEN_GAME);
        return;
      }
      Logic.the.changeState(States.IDLE);
    });
  }

  public exitIntroState(nextState: States): void {
    SlotMachine.initSlotMachine(setSlotConfig());
    // overriding it here coz slotmachine index.ts is a critical file, and cannot be modified.
    SlotMachine.the().throwTimeoutError = () => {
      if (!setIsRevokeThrowingError()) {
        setIsTimeoutErrorMessage(true);
      }
      eventManager.emit(EventTypes.THROW_ERROR);
    };
    eventManager.emit(EventTypes.FORCE_RESIZE);
  }

  public enterBrokenGameState(prevState: States): void {
    setIsProcessToGame(true);
    const bonus = setCurrentBonus();
    SlotMachine.the().onBrokenGame(bonus);
    Logic.the.changeState(States.TRANSITION);
    Logic.the.changeGameMode(bonus.gameMode, { bonus, immediate: true });
  }

  public enterIdleState(prevState: States): void {
    if (prevState === States.SPIN) {
      eventManager.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE);
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      setIsSpinInProgress(false);
      setIsSlotBusy(false);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
      return;
    }
    // workaround when we buy feature durring offline mode
    if (
      prevState === States.INIT ||
      prevState === States.INTRO ||
      prevState === States.BROKEN_GAME ||
      setIsBuyFeaturePurchased()
    ) {
      // const debug = new Debug();
      // debug.x = 800;
      // Logic.the.application.stage.addChild(debug);
      // Logic.the.application.ticker.add(() => debug.update());
      return;
    }
    this.slotIdleTimeout = setTimeout(() => {
      AudioHowl.stop({ type: ISongs.BGM_BG_Melo_Loop });
      AudioHowl.play({ type: ISongs.BGM_BG_Base_Loop });
    }, 20000);
    eventManager.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE);
    setIsSpinInProgress(false);
    setIsSlotBusy(false);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, getBetResult(setBetResult()).balance.settled);

    client.writeQuery({
      query: isStoppedGql,
      data: {
        isSlotStopped: true,
      },
    });
    this.handleHistory();
  }

  public enterSpinState(prevState: States): void {
    clearTimeout(this.slotIdleTimeout);
    if (AudioHowl.isPlaying(ISongs.BGM_BG_Base_Loop)) {
      AudioHowl.stop({ type: ISongs.BGM_BG_Base_Loop });
      AudioHowl.play({ type: ISongs.BGM_BG_Melo_Loop });
    }
    eventManager.emit(EventTypes.DISABLE_PAYTABLE);
    eventManager.emit(EventTypes.CLOSE_ALL_EYES);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
    SlotMachine.the().spinSpinAnimation();
  }

  public enterBeforeWinState(prevState: States): void {
    client.writeQuery({
      query: isStoppedGql,
      data: {
        isSlotStopped: false,
      },
    });
    const betResult = getBetResult(setBetResult());
    if (betResult.bet.result.winCoinAmount > 0) {
      Logic.the.changeState(States.WIN_PRESENTATION);
    } else {
      Logic.the.changeState(States.IDLE);
    }
  }

  public enterWinPresentationState(prevState: States): void {
    eventManager.once(EventTypes.END_CASCADE_FEATURE, () => {
      Logic.the.changeState(States.AFTER_WIN);
    });

    eventManager.emit(EventTypes.START_CASCADE_FEATURE, getBetResult(setBetResult()).bet.data.features.cascade);
  }

  public enterAfterWinState(prevState: States): void {
    eventManager.emit(EventTypes.HIDE_COUNT_UP);
    const { winCoinAmount } = getBetResult(setBetResult()).bet.result;
    setWinAmount(winCoinAmount);
    setLastRegularWinAmount(winCoinAmount);
    if (getWinStage(winCoinAmount) >= WinStages.BigWin) {
      eventManager.once(EventTypes.END_BIG_WIN_PRESENTATION, () => {
        setTimeout(() => Logic.the.changeState(States.JINGLE), 500);
      });
      eventManager.emit(EventTypes.START_BIG_WIN_PRESENTATION, winCoinAmount);
    } else {
      setTimeout(() => Logic.the.changeState(States.JINGLE), 500);
      const multiplier = normalizeCoins(winCoinAmount) / normalizeCoins(setBetAmount());
      if (multiplier > 7) {
        AudioHowl.play({ type: ISongs.HighWin, stopPrev: true });
        return;
      }
      if (multiplier >= 5) {
        AudioHowl.play({ type: ISongs.MediumWin, stopPrev: true });
        return;
      }
      if (multiplier >= 3) {
        AudioHowl.play({ type: ISongs.SmallWin, stopPrev: true });
      }
    }
  }

  public enterJingleState(prevState: States): void {
    const result = getBetResult(setBetResult());
    if (result.bet.data.bonuses.length > 0) {
      setIsFreeSpinsWin(true);
      eventManager.emit(EventTypes.WIN_EYE);
      const [bonus] = result.bet.data.bonuses;
      setCurrentBonus({
        ...bonus,
        isActive: true,
        currentRound: 0,
      });
      setFreeSpinsTotalWin(result.bet.result.winCoinAmount);
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, getBetResult(setBetResult()).balance.settled);
      setTimeout(() => {
        Logic.the.changeState(States.TRANSITION);
        Logic.the.changeGameMode(GameMode.FREE_SPINS, {
          bonus,
        });
      }, 1000);
      return;
    }
    Logic.the.changeState(States.IDLE);
  }

  public enterController(prevGameMode: GameMode, props?: EnterProps): void {
    updateCoinValueAfterBonuses();

    AudioHowl.play({ type: ISongs.BGM_BG_Base_Loop });
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.emit(EventTypes.IMMEDIATE_CLOSE_EYES);
    eventManager.on(EventTypes.HANDLE_BUY_BONUS, (bonusId) => {
      Logic.the.changeState(States.TRANSITION);
      Logic.the.changeGameMode(GameMode.BUY_FEATURE, { bonusId });
    });
    // overriding it here coz slotmachine index.ts is a critical file, and cannot be modified.
    eventManager.addListener(EventTypes.END_WAITING_ANIMATION, () => {
      SlotMachine.the().reelsContainer.reels.forEach((reel: Reel) => {
        reel.cascadeAnimation?.getWaiting().cleanUpOnComplete();
      });
    });
    if (prevGameMode === null) return;
    setIsFreeSpinsWin(false);

    if (prevGameMode === GameMode.FREE_SPINS || prevGameMode === GameMode.RAGE_MODE) {
      setWinAmount(setFreeSpinsTotalWin());
    }
    Logic.the.changeState(States.IDLE);
  }

  public exitController(nextGameMode: GameMode): void {
    AudioHowl.stop({ type: ISongs.BGM_BG_Base_Loop });
    AudioHowl.stop({ type: ISongs.BGM_BG_Melo_Loop });
    clearTimeout(this.slotIdleTimeout);
    eventManager.removeListener(EventTypes.HANDLE_BUY_BONUS);
    eventManager.removeListener(EventTypes.END_WAITING_ANIMATION);
  }

  public setResult(result: ISettledBet): void {
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, result.balance.placed);
    setUserBalance({ ...setUserBalance(), balance: result.balance.placed });
    const newResult = JSON.parse(JSON.stringify(result));
    newResult.bet.result.spinResult = getSpinResult({
      reelPositions: result.bet.result.reelPositions,
      reelSet: result.bet.reelSet,
      icons: setSlotConfig().icons,
    });
    setBetResult(newResult);
  }

  private handleHistory(): void {
    const betResult = getBetResult(setBetResult());
    const win = betResult.bet.result.winCoinAmount;
    const lastThreeSpins = [...setGameHistory().slice(1), !!win];
    setGameHistory(lastThreeSpins);
    setUserBalance({ ...setUserBalance(), balance: betResult.balance.settled });
    saveReelPosition(betResult.bet.result.reelPositions);
  }
}

import { isMobile } from 'mobile-device-detect';
import { Spine } from 'pixi-spine';
import { Container, Graphics, Loader, Sprite, Texture } from 'pixi.js';

import { EventTypes, GameMode, UserBonus } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { ViewContainer } from '../components/ViewContainer';
import { SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH, eventManager } from '../config';
import { IGameContainer } from '../d';
import CoinsAnimationContainer from '../winAnimations/coinsAnimationContainer';
import WinCountUpMessage from '../winAnimations/winCountUpMessage';

import { FreeSpinsLeft } from './freeSpinsLeft';
import GameReplay from './gameReplay';
import ProgressBar from './progressBar';
import ProgressBarFreeSpins from './progressBarFreeSpins';

class GameView extends ViewContainer {
  public winSlotsContainer: Container;

  public miniPayTableContainer: Container;

  public reelsBackgroundContainer: Container;

  public reelsContainer: Container;

  public slotsContainer: Container;

  public winCountUpMessage: WinCountUpMessage;

  public progressBar: ProgressBar;

  public coinsAnimationContainer: Container;

  public frame: Sprite;

  public frameAmbientBack: Spine;

  public frameAmbientFront: Spine;

  public gameLogo: Spine;

  public gameReplay: Container;

  public freeSpinsLeft: FreeSpinsLeft;

  public progressBarFreeSpins: ProgressBarFreeSpins;

  public rageModeLabel: Sprite;

  public maskArea: Graphics;

  constructor(props: IGameContainer) {
    super();
    this.sortableChildren = true;
    this.slotsContainer = new Container();
    this.slotsContainer.width = SLOTS_CONTAINER_WIDTH;
    this.slotsContainer.height = SLOTS_CONTAINER_HEIGHT;
    this.slotsContainer.x = 0;
    this.slotsContainer.y = 112;
    this.maskArea = new Graphics()
      .beginFill(0xffffff)
      .drawRect(0, 0, SLOTS_CONTAINER_WIDTH, SLOTS_CONTAINER_HEIGHT)
      .endFill();

    this.slotsContainer.interactive = true;
    this.progressBar = new ProgressBar();
    this.freeSpinsLeft = new FreeSpinsLeft();
    this.gameLogo = this.initGameLogo();
    this.gameReplay = new GameReplay();
    this.coinsAnimationContainer = new CoinsAnimationContainer();
    this.winSlotsContainer = props.winSlotsContainer;
    this.winSlotsContainer.y = this.slotsContainer.y;
    this.miniPayTableContainer = props.miniPayTableContainer;
    this.miniPayTableContainer.x = this.slotsContainer.x;
    this.miniPayTableContainer.y = this.slotsContainer.y;
    this.reelsBackgroundContainer = props.reelsBackgroundContainer;
    this.reelsContainer = props.reelsContainer;
    this.winCountUpMessage = props.winCountUpMessage;
    this.slotsContainer.addChild(this.reelsBackgroundContainer);
    this.slotsContainer.addChild(this.reelsContainer);
    this.slotsContainer.addChild(this.maskArea);
    this.slotsContainer.mask = this.maskArea;
    this.progressBarFreeSpins = new ProgressBarFreeSpins();
    this.addChild(this.slotsContainer);
    this.frame = this.initDesktopReelsFrame();
    this.frameAmbientBack = this.initFrameAmbientBack();
    this.frameAmbientFront = this.initFrameAmbientFront();
    this.rageModeLabel = this.initRageModeLabel();
    this.addChild(this.frameAmbientBack);
    this.addChild(this.frame);
    this.addChild(this.frameAmbientFront);
    this.addChild(this.miniPayTableContainer);
    this.addChild(this.progressBar);
    this.addChild(this.progressBarFreeSpins);
    this.addChild(this.rageModeLabel);
    this.addChild(this.gameLogo);
    this.addChild(this.gameReplay);
    this.addChild(this.freeSpinsLeft);
    this.addChild(this.winSlotsContainer);
    this.addChild(this.coinsAnimationContainer);
    this.addChild(this.winCountUpMessage);
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resizeGameContainer.bind(this));
  }

  protected onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.handleBaseModeChanges();
        break;
      case GameMode.FREE_SPINS:
        this.handleFreeSpinsChanges();
        break;
      case GameMode.RAGE_MODE:
        this.handleRageModeChanges();
        break;
      default:
        this.handleBaseModeChanges();
        break;
    }
  }

  private handleBaseModeChanges(): void {
    this.progressBar.visible = true;
    this.progressBarFreeSpins.visible = false;
    this.rageModeLabel.visible = false;
    this.gameLogo.state.setAnimation(0, 'logo_base_game', true);
    this.frame.texture = Texture.from(ResourceTypes.frame);
    this.frameAmbientBack.state.setAnimation(0, 'reel_normal_back', true);
    this.frameAmbientFront.state.setAnimation(0, 'reel_normal_front', true);
  }

  private handleFreeSpinsChanges(): void {
    this.progressBar.visible = false;
    this.progressBarFreeSpins.visible = true;
    this.rageModeLabel.visible = false;
    this.gameLogo.state.setAnimation(0, 'logo_free_spins', true);
    this.frame.texture = Texture.from(ResourceTypes.frameFreeSpins);
    this.frameAmbientBack.state.setAnimation(0, 'reel_free_spins_back', true);
    this.frameAmbientFront.state.setAnimation(0, 'reel_free_spins_front', true);
  }

  private handleRageModeChanges(): void {
    this.progressBar.visible = false;
    this.progressBarFreeSpins.visible = false;
    this.rageModeLabel.visible = true;
    this.gameLogo.state.setAnimation(0, 'logo_rage_mode', true);
    this.frame.texture = Texture.from(ResourceTypes.frameRageMode);
    this.frameAmbientBack.state.setAnimation(0, 'reel_rage_mode_back', true);
    this.frameAmbientFront.state.setAnimation(0, 'reel_rage_mode_front', true);
  }

  private initGameLogo(): Spine {
    const gameLogo = new Spine(Loader.shared.resources.logo.spineData!);
    gameLogo.x = 1120;
    gameLogo.y = 50;
    gameLogo.state.setAnimation(0, 'logo_base_game', true);
    return gameLogo;
  }

  private initDesktopReelsFrame(): Sprite {
    const frame = new Sprite(Texture.from(ResourceTypes.frame));
    frame.y = -14;
    frame.x = -170;
    return frame;
  }

  private initFrameAmbientBack(): Spine {
    const frameAmbientBack = new Spine(Loader.shared.resources.reelAmbient.spineData!);
    frameAmbientBack.y = -14 + this.frame.height / 2;
    frameAmbientBack.x = -170 + this.frame.width / 2;
    frameAmbientBack.state.setAnimation(0, 'reel_normal_back', true);

    return frameAmbientBack;
  }

  private initFrameAmbientFront(): Spine {
    const frameAmbientFront = new Spine(Loader.shared.resources.reelAmbient.spineData!);
    frameAmbientFront.y = -14 + this.frame.height / 2;
    frameAmbientFront.x = -170 + this.frame.width / 2;
    frameAmbientFront.state.setAnimation(0, 'reel_normal_front', true);

    return frameAmbientFront;
  }

  private initRageModeLabel(): Sprite {
    const rageModeLabel = new Sprite(Texture.from(ResourceTypes.labelRageMode));
    rageModeLabel.scale.set(0.7);
    rageModeLabel.position.set(-100, -80);
    rageModeLabel.visible = false;
    return rageModeLabel;
  }

  private resizeGameContainer(width: number, height: number): void {
    this.scale.set(width / SLOTS_CONTAINER_WIDTH);
  }

  protected resize(width: number, height: number): void {
    if (!isMobile) return;
    const isLandscape = width > height;
    this.gameLogo.x = isLandscape ? 1050 : 650;
    this.gameLogo.y = isLandscape ? 50 : -170;
    this.gameLogo.width = isLandscape ? 460 : 650;
    this.gameLogo.height = isLandscape ? 120 : 170;
    this.rageModeLabel.position.set(isLandscape ? -100 : 140, -80);

    // this.rageModeLabel.x = isLandscape ? -1050 : 650;
    // this.rageModeLabel.y = isLandscape ? -50 : -280;
    // this.rageModeLabel.width = isLandscape ? 460 : 820;
    // this.rageModeLabel.height = isLandscape ? 140 : 270;
  }
}

export default GameView;

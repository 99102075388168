import { Stage } from '@pixi/layers';
import { Application } from 'pixi.js';

import { EnterProps, EventTypes, GameMode } from '../global.d';
import { setBetResult, setIsAutoSpins, setIsErrorMessage } from '../gql/cache';
import Animator from '../slotMachine/animations/animator';
import { GAME_CONTAINER_HEIGHT, GAME_CONTAINER_WIDTH, eventManager } from '../slotMachine/config';

import { IPixiViewParentNode, States } from './config';
import { BaseController } from './controllers/BaseController';
import { BuyFeatureController } from './controllers/BuyFeatureController';
import { Controller } from './controllers/Controller';
import { FreeSpinController } from './controllers/FreeSpinController';
import { RageModeController } from './controllers/RageModeController';
import { AfterWin, BeforeWin, Idle, Init, Intro, Jingle, Spin, Transition, WinPresentation } from './states';
import { BrokenGame } from './states/BrokenGame';
import { State } from './states/State';

export class Logic {
  public state: State = Init.the;

  public stopped = false;

  public isReadyForStop = false;

  public isStoppedBeforeResult = false;

  public controller: Controller;

  public static the = new Logic();

  public application: Application;

  public animator: Animator;

  private constructor() {
    this.registerStates();
    this.application = new Application({
      resolution: window.devicePixelRatio || 1,
      autoDensity: true,
      backgroundAlpha: 0,
      width: GAME_CONTAINER_WIDTH,
      height: GAME_CONTAINER_HEIGHT,
    });
    this.application.stage = new Stage();
    this.animator = new Animator(this.application);
    this.controller = BaseController.the;
    this.controller.enterController(null);
  }

  public init(): void {
    window.addEventListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.on(EventTypes.FORCE_RESIZE, this.handleResize.bind(this));

    eventManager.on(EventTypes.REELS_STOPPED, () => {
      if (setIsErrorMessage()) {
        this.changeState(States.IDLE);
        setIsErrorMessage(false);
        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      } else {
        this.changeState(States.BEFORE_WIN);
      }
    });
    this.state = Init.the;
    this.state.enterState(States.INIT);
  }

  private registerStates(): void {
    Init.the.nodes.set(States.IDLE, Idle.the);
    Init.the.nodes.set(States.BROKEN_GAME, BrokenGame.the);
    Init.the.nodes.set(States.INTRO, Intro.the);

    Intro.the.nodes.set(States.BROKEN_GAME, BrokenGame.the);
    Intro.the.nodes.set(States.IDLE, Idle.the);

    BrokenGame.the.nodes.set(States.TRANSITION, Transition.the);

    Idle.the.nodes.set(States.SPIN, Spin.the);
    Idle.the.nodes.set(States.TRANSITION, Transition.the);

    Spin.the.nodes.set(States.BEFORE_WIN, BeforeWin.the);
    Spin.the.nodes.set(States.IDLE, Idle.the);

    BeforeWin.the.nodes.set(States.WIN_PRESENTATION, WinPresentation.the);
    BeforeWin.the.nodes.set(States.IDLE, Idle.the);

    WinPresentation.the.nodes.set(States.AFTER_WIN, AfterWin.the);

    AfterWin.the.nodes.set(States.JINGLE, Jingle.the);

    Jingle.the.nodes.set(States.TRANSITION, Transition.the);
    Jingle.the.nodes.set(States.IDLE, Idle.the);

    Transition.the.nodes.set(States.IDLE, Idle.the);
  }

  public changeState(nextState: States): void {
    this.state.exitState(nextState);
    if (!this.state.nodes.has(nextState)) throw Error(`Invalid change state from ${this.state.name} to ${nextState}`);
    const currentState = this.state.name;
    this.state = this.state.nodes.get(nextState)!;
    this.state.enterState(currentState);
    eventManager.emit(EventTypes.CHANGE_STATE, nextState);
  }

  public spin(): void {
    this.isReadyForStop = false;
    this.isStoppedBeforeResult = false;
    setBetResult(null);
    this.changeState(States.SPIN);
  }

  public handleInsufficientFunds(): void {
    eventManager.emit(EventTypes.ROLLBACK_REELS);
    if (setIsAutoSpins()) setIsAutoSpins(false);
    this.changeState(States.IDLE);
  }

  public quickStop(): void {
    if (this.isReadyForStop) {
      eventManager.emit(EventTypes.FORCE_STOP_REELS);
    } else {
      this.isStoppedBeforeResult = true;
    }
  }

  public skipWinAnimation(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
  }

  public changeGameMode(nextGameMode: GameMode, enterProps?: EnterProps): void {
    if (this.state.name !== States.TRANSITION) throw new Error('WRONG STATE FOR CHANGING MODE');
    const currentGameMode = this.controller.gameMode;
    let nextController: Controller;
    switch (nextGameMode) {
      case GameMode.BASE_GAME:
        nextController = BaseController.the;
        break;
      case GameMode.BUY_FEATURE:
        nextController = BuyFeatureController.the;
        break;
      case GameMode.FREE_SPINS:
        nextController = FreeSpinController.the;
        break;
      case GameMode.RAGE_MODE:
        nextController = RageModeController.the;
        break;
      default:
        nextController = BaseController.the;
        break;
    }
    if (nextController === BuyFeatureController.the || enterProps?.immediate) {
      this.controller.exitController(nextGameMode);
      eventManager.emit(EventTypes.CHANGE_MODE, { mode: nextGameMode });
      this.controller = nextController;
      this.controller.enterController(currentGameMode, enterProps);
      return;
    }
    eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, () => {
      this.controller.exitController(nextGameMode);
      eventManager.emit(EventTypes.CHANGE_MODE, { mode: nextGameMode });
      this.controller = nextController;
      this.controller.enterController(currentGameMode, enterProps);
    });
  }

  private handleResize(): void {
    const parent = this.application.view.parentNode as IPixiViewParentNode;
    const width = parent?.clientWidth;
    const height = parent?.clientHeight;
    eventManager.emit(EventTypes.RESIZE, width, height);
    this.application.renderer.resize(width, height);
  }

  private resize(): void {
    const { userAgent } = navigator;
    // resize fix for Chrome browsers on Ios devices
    if (userAgent.includes('CriOS') && (userAgent.includes('iPhone') || userAgent.includes('iPad'))) {
      setTimeout(() => {
        this.handleResize();
      }, 50);
    } else {
      this.handleResize();
    }
  }

  public isReadyToSpin(): boolean {
    return this.controller.gameMode === GameMode.BASE_GAME && this.state.name === States.IDLE;
  }

  public isReadyToStop(): boolean {
    return this.state.name === States.SPIN;
  }

  public isReadyToSkip(): boolean {
    return this.state.name === States.AFTER_WIN;
  }

  public isStopped(): boolean {
    return this.stopped;
  }
}

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { EventTypes, GameMode, ISettledBet } from '../../global.d';
import {
  setBetAmount,
  setBetResult,
  setBrokenGame,
  setCurrentBonus,
  setFreeSpinsTotalWin,
  setIsSlotBusy,
  setIsSpinInProgress,
  setLastRegularWinAmount,
  setSlotConfig,
} from '../../gql/cache';
import client from '../../gql/client';
import { isStoppedGql } from '../../gql/query';
import SlotMachine from '../../slotMachine';
import { PopupTypes, WinStages, eventManager } from '../../slotMachine/config';
import { PopupController } from '../../slotMachine/popups/PopupController';
import {
  getBGMSoundByGameMode,
  getBetResult,
  getSpinResult,
  getWinStage,
  isRageModeWin,
  normalizeCoins,
} from '../../utils';
import { States } from '../config';
import { Logic } from '../index';

import { BaseController } from './BaseController';

export class FreeSpinController extends BaseController {
  public gameMode: GameMode = GameMode.FREE_SPINS;

  public static the = new FreeSpinController();

  protected constructor() {
    super();
  }

  public enterIdleState(prevState: States): void {
    setIsSlotBusy(false);
    if (setCurrentBonus().currentRound === setCurrentBonus().rounds) {
      AudioHowl.fadeOut(1000, getBGMSoundByGameMode(this.gameMode));
      PopupController.the.openPopup(PopupTypes.FREE_SPINS_END);
      eventManager.once(EventTypes.END_FREE_SPINS, () => {
        PopupController.the.closeCurrentPopup();
        Logic.the.changeState(States.TRANSITION);
        Logic.the.changeGameMode(GameMode.BASE_GAME);
      });
      return;
    }
    setTimeout(() => eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND), 500);
  }

  public enterSpinState(prevState: States): void {
    eventManager.emit(EventTypes.CLOSE_ALL_MULTIPLIER_EYES);
    eventManager.emit(EventTypes.DISABLE_PAYTABLE);
    SlotMachine.the().spinSpinAnimation();
    setCurrentBonus({
      ...setCurrentBonus(),
      currentRound: setCurrentBonus().currentRound + 1,
    });
  }

  public enterWinPresentationState(prevState: States): void {
    eventManager.once(EventTypes.END_CASCADE_FEATURE, () => {
      eventManager.emit(EventTypes.MULTIPLIER_EYE_WIN);
      setTimeout(() => Logic.the.changeState(States.AFTER_WIN), 1000);
    });

    eventManager.emit(EventTypes.START_CASCADE_FEATURE, getBetResult(setBetResult()).bet.data.features.cascade);
  }

  public enterAfterWinState(prevState: States): void {
    const result = getBetResult(setBetResult());
    if (getWinStage(result.bet.result.winCoinAmount) >= WinStages.BigWin) {
      eventManager.emit(EventTypes.HIDE_COUNT_UP, 0);
      eventManager.once(EventTypes.END_BIG_WIN_PRESENTATION, () => {
        setTimeout(() => Logic.the.changeState(States.JINGLE), 500);
      });
      eventManager.emit(EventTypes.START_BIG_WIN_PRESENTATION, result.bet.result.winCoinAmount);
    } else {
      eventManager.once(EventTypes.COUNT_UP_END, () => {
        eventManager.emit(EventTypes.HIDE_COUNT_UP);
        setTimeout(() => Logic.the.changeState(States.JINGLE), 500);
        const multiplier = normalizeCoins(result.bet.result.winCoinAmount) / normalizeCoins(setBetAmount());
        if (multiplier > 7) {
          AudioHowl.play({ type: ISongs.HighWin, stopPrev: true });
          return;
        }
        if (multiplier >= 5) {
          AudioHowl.play({ type: ISongs.MediumWin, stopPrev: true });
          return;
        }
        if (multiplier >= 3) {
          AudioHowl.play({ type: ISongs.SmallWin, stopPrev: true });
        }
      });
      const winBeforeMultiplier = result.bet.data.features.cascade.reduce((acc, c) => {
        return acc + c.winAmounts.reduce((acc, amount) => acc + amount, 0);
      }, 0);
      eventManager.emit(EventTypes.START_COUNT_UP, winBeforeMultiplier, result.bet.result.winCoinAmount, -1);
    }
  }

  public enterJingleState(prevState: States): void {
    if (setFreeSpinsTotalWin() > 0) {
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setFreeSpinsTotalWin());
    }
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, getBetResult(setBetResult()).balance.settled);
    if (isRageModeWin(getBetResult(setBetResult())) && setCurrentBonus().currentRound !== setCurrentBonus().rounds) {
      Logic.the.changeState(States.TRANSITION);
      Logic.the.changeGameMode(GameMode.RAGE_MODE);
      return;
    }
    Logic.the.changeState(States.IDLE);
  }

  public setResult(result: ISettledBet): void {
    const spinResult = getSpinResult({
      reelPositions: result.bet.result.reelPositions,
      reelSet: result.bet.reelSet,
      icons: setSlotConfig().icons,
    });
    eventManager.emit(
      EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE,
      setCurrentBonus().currentRound,
      setCurrentBonus().rounds,
    );
    setFreeSpinsTotalWin(setFreeSpinsTotalWin() + result.bet.result.winCoinAmount);
    const newResult: ISettledBet = JSON.parse(JSON.stringify(result));
    newResult.bet.result.spinResult = spinResult;
    setBetResult(newResult);
  }

  public enterController(prevGameMode: GameMode): void {
    if (setBrokenGame()) AudioHowl.play({ type: ISongs.BGM_FS_Loop });
    setIsSlotBusy(false);
    eventManager.emit(EventTypes.IMMEDIATE_CLOSE_ALL_MULTIPLIER_EYES);
    eventManager.emit(
      EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE,
      setCurrentBonus().currentRound,
      setCurrentBonus().rounds,
    );
    eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    if (setFreeSpinsTotalWin() > 0) {
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setFreeSpinsTotalWin());
    }
    if (setBrokenGame()) {
      Logic.the.changeState(States.IDLE);
      return;
    }
    eventManager.once(EventTypes.START_FREE_SPINS, () => {
      PopupController.the.closeCurrentPopup();
      Logic.the.changeState(States.IDLE);
    });
    PopupController.the.openPopup(PopupTypes.FREE_SPINS);
  }

  public exitController(nextGameMode: GameMode): void {
    setLastRegularWinAmount(setFreeSpinsTotalWin());
    AudioHowl.stop({ type: ISongs.BGM_FS_Loop });
    if (setBrokenGame()) setBrokenGame(false);
    setCurrentBonus({ ...setCurrentBonus(), isActive: false });
    eventManager.emit(EventTypes.HIDE_COUNT_UP);
  }
}
